import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';

const request = {
    submit : false,
    success : false,
    error : false,
  },
  error = {
    submit : false,
    success : false,
    error : true,
  },
  success = {
    submit : true,
    success : true,
    error : false,
  };

@Component({
  selector: 'app-cta-form-ecommerce',
  templateUrl: './cta-form-ecommerce.component.html',
  styleUrls: ['./cta-form-ecommerce.component.scss']
})
export class CtaFormEcommerceComponent implements OnInit {

  public talkToUsForm: FormGroup;
  public formClass: any = '';
  public submitted: any = false;
  public loading: any = false;
  public request: any = request;
  route: string;
  public href = '';
  url = 'asdf';
  dialCode: any = '1';
  ipAddress = '';

//  @Input() set routeUrl(value: string) {

//   if (typeof value !== 'undefined' ) {
//     console.log(value, 'VALUE' );
//     this.defaultEcommerceApproxBudget = !(value.includes('/woocommerce'));
//   }
// }


public defaultEcommerceApproxBudget = true;
  constructor(private http: HttpClient, private router: Router) {  }

  @Input() name: any;

  ngOnInit() {

    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });

    this.href = this.router.url;
    this.defaultEcommerceApproxBudget = !(this.href.includes('/woocommerce'));
    this.formClass = this.name || '';
    this.talkToUsForm = new FormGroup({
      name : new FormControl(''),
      phone : new FormControl('', Validators.required),
      email : new FormControl('', Validators.required),
      company : new FormControl(''),
      message : new FormControl(''),
      budget : new FormControl(''),
      pageRoute : new FormControl(''),
      userIP : new FormControl(''),
      refUrl : new FormControl(''),
      dialCode : new FormControl(''),
      // services: new FormGroup({
      //   woocommerce : new FormControl(false),
      //   magento : new FormControl(false),
      //   shopify : new FormControl(false),
      // })
    });
  }

  clickMe() {
    console.log('CLICK ME');
  }

  getNumber(obj) {
    // console.log(obj);
  }

  hasError(obj) {
    console.log(obj);
  }

  telInputObject(obj) {
    // console.log(obj);
  }
  
  onCountryChange(obj) {
    console.log(obj.dialCode);
    this.dialCode = obj.dialCode;
  }

  onSubmit() {
    this.submitted = true;

    if (this.talkToUsForm.invalid) {
      return null;
    }
    
    console.log(this.ipAddress, "ip address");

    this.loading = true;
    this.href = this.router.url;
    const pageUrl = this.href;
    this.talkToUsForm.value.pageRoute = pageUrl;
    this.talkToUsForm.value.dialCode = this.dialCode;
    this.talkToUsForm.value.userIP = this.ipAddress;
    
    const initreferrer = document.referrer;
    this.talkToUsForm.value.refUrl = initreferrer;
    console.log(initreferrer, "ref url");

    console.log( this.talkToUsForm.value);

    if (localStorage.getItem('mysite_referrer') === null) {
      localStorage.setItem('mysite_referrer', initreferrer);
    }

    if (localStorage.getItem('mysite_referrer')){
      this.talkToUsForm.value.refUrl = localStorage.getItem('mysite_referrer');
      console.log('now defined');
    } else {
      console.log('undefined');
    }

    this.http.post<any>(`https://koderlabs.com:3000/mail/magento`, this.talkToUsForm.value)
      .subscribe(
        resp => {
          this.request = (resp && resp.length && resp[1] && resp[1].status) ? success : error;
          document.getElementById('clickHere').click();
          this.loading = false;
        },
        err => {
          this.request = error;
          this.loading = false;
        }
      );
  }

  tryAgain() {
    this.request = request;
  }


}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculate',
  templateUrl: './calculate.component.html',
  styleUrls: ['./calculate.component.scss']
})
export class CalculateComponent implements OnInit {

  public topView: any;
  public feature: any;
  constructor() { }

  ngOnInit() {
    this.topView = {
      secondaryHeading : `Get an estimated of<br> your project`,
      subText : 'This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and ' +
              'feel of finished, typeset text. Only for show.',
    };

    this.feature = {
      title: 'What type of project do you need?',
      items: [
        {icon: 'kl-apple', title: 'How Much to Make an App'},
        {icon: 'kl-android', title: 'How Much to Make a Web App'},
        {icon: 'kl-apple', title: 'How Much a CMS Cost'},
        {icon: 'kl-android', title: 'Calculate Business Solution Cost'}
      ]
    };

  }

}

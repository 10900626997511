import { Component, OnInit } from '@angular/core';

const technologies = [
  {name : 'Mobile', technologies : [
      {img : 'android.png' },
      {img : 'apple.png' },
      {img : 'ionic.png' },
      {img : 'x.png' },
      {img : 'react-native.png' },
      {img : 'phone-gap.png' },
      {img : 'framework-7.png' },
      {img : 'intel-xdx.png' },
      {img : 'titanium.png' },
      {img : 'onsen-ui.png' },
    ]},
  {name : 'Ecommerce', technologies : [
      {img : 'shopify.png' },
      {img : 'magento.png' },
      {img : 'yokart.png' },
      {img : 'big-commerce.png' },
      {img : 'vtex.png' },
      {img : 'woo.png' },
      {img : 'tictail.png' },
      {img : 'opencart.png' },
    ]},
  {name : 'Backend', technologies : [
      {img : 'php.png' },
      {img : 'java.png' },
      {img : 'dotnet.png' },
      {img : 'node.png' },
      {img : 'python.png' },
      {img : 'ruby-on-rails.png' },
      {img : 'symfony.png' },
      {img : 'laravel.png' }
    ]},
  {name : 'Frontend', technologies : [
      {img : 'angular.png' },
      {img : 'react-native.png' },
      {img : 'Vue-js.png' },
      {img : 'meteor.png' },
      {img : 'jQuery.png' },
      {img : 'foundation.png' },
      {img : 'bootstrap.png' },
      {img : 'skeleton.png' },
      {img : 'kendo-ui.png' },
      {img : 'mobile-angular-ui.png' },
    ]},
  {name : 'Testing', technologies : [
      {img : 'runscope.png' },
      {img : 'soap-UI.png' },
      {img : 'postman.png' },
      {img : 'charles.png' },
      {img : 'test-rail.png' },
      {img : 'appium.png' },
      {img : 'fabric.png' },
      {img : 'wind.png' },
    ]},
  {name : 'Web CMS', technologies : [
      {img : 'wordpress.png' },
      {img : 'eye.png' },
      {img : 'joomla.png' },
      {img : 'ExpressionEngine.png' },
      {img : 'TextPattern.png' },
      {img : 'Radiant-CMS.png' }
    ]},
];



@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {

  public technologies: any;
  public selectedTechnology: any;
  constructor() { }

  ngOnInit() {
    this.technologies = technologies;
    this.selectedTechnology = technologies[0];
  }


  changeTechnology(name) {
    this.selectedTechnology = technologies.find((technology) => technology.name === name);
  }

}

import {AfterViewInit, Component, ElementRef, HostListener, OnInit, Input, ViewChild} from '@angular/core';
import { ModalService } from './../../components/modal/modal.service';
import {EventsService} from '../../services/events.service';

declare var jQuery: any;

let previousPosition = 0;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public opened: any;
  public hideHeader: any = false;
  public smallHeightHeader: any = false;
  public defaultMenu : boolean = true;

  @Input() set routeUrlHeader(value: string) {

    if(typeof value !== 'undefined' ){
      this.defaultMenu = !(value.includes('/magento') ||  value.includes('/shopify') ||  value.includes('/woocommerce') ||  value.includes('/thankyou') ||  value.includes('/webdesign') ||  value.includes('/ecommerce'));
      //console.log("Hello: " + value);
    }

  };

  constructor(private modalService: ModalService, private eventsService: EventsService) {

  }

  @ViewChild('header') header: ElementRef;

  ngOnInit() {

    // window.onload = async () => {
    //   console.log("after load");
    //   this.functionNr1();
    // };
    
    this.opened = false;
    this.eventsService.menuButtonEmitter.subscribe((bol) => {
      this.opened = bol;
    });

    this.eventsService.scrollEmitter.subscribe((window) => {
      this.scrollPosition(window);
    });

  }

  //Catch window scroll position
  scrollPosition(window) {
    const fourthHeightOfWindow = (window.innerHeight / 4),
      currentPosition = window.scrollY ;

    if (currentPosition >= fourthHeightOfWindow) {
      this.hideHeader = (currentPosition > previousPosition);
      this.smallHeightHeader = true;
    } else {
      this.hideHeader = false;
      this.smallHeightHeader = false;
    }
    previousPosition = currentPosition;
  }

  // Toggle menu button
  menuToggle() {
    this.opened = !this.opened;
    this.eventsService.toggleMenu(this.opened);
  }

  
  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  functionNr1() {
      // setTimeout (() => {
      //   this.modalService.open('custom-modal-2');
      // }, 7000);
  }

  startProject() {
    window.scrollTo({
      top:  document.documentElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technologies-page',
  templateUrl: './technologies-page.component.html',
  styleUrls: ['./technologies-page.component.scss']
})
export class TechnologiesPageComponent implements OnInit {

  public topView: any;
  public developmentProcess: any;
  public activeProcessIndex: any = 0;
  public cards: any;
  constructor() { }

  ngOnInit() {
    this.topView = {
      primaryHeading : `We transform Ideas into results through powerful technologies`,
      subText : 'Turn your ideas into reality. Our team of experts along with a collaborative approach deliver exceptional results.'
    };

    this.developmentProcess = [
      {title : 'Research' , description: `Industry research <br> Product Analysis  <br> Case Methodology`},
      {title : 'Planning' , description: `Sitemaps  <br>Functional Requirements <br> Workflow`},
      {title : 'Design' , description: `UI Structure <br> Visual Summary <br> Product Plo`},
      {title : 'Development' , description: `Finance Report <br> Additional Expense <br> Progress Report`},
      {title : 'Quality Assurance' , description: `Examination <br> Bugs Reports <br> Debug Repeat`},
      {title : 'Maintenance' , description: `Structure Details <br>Backend database <br> Update Reports`}
    ];

    this.cards = {
      title :   `The secret to our success`,
      items : [
        {name: 'Quality Assurance' , description: 'Projects are supervised by our QA team who vigorously test the product from end-to-end. We strive for every platform to work  flawlessly.'},
        {name: 'Resources' , description: 'We employ the best people with the most Advanced skills. With proven methodologies, we build every app with high competence'},
        {name: 'Secure Solutions' , description: 'From database to projects to coding and from discussion of ideas to their privacy, we are concerned  about every possible aspect of project security. We directly employ our Programmers. Keeping resources in-house ensures that your project stays your project.'},
        {name: 'Sustainability' , description: 'Be it coding,  planning  or design, we organize each aspect of your project so that it can be easily maintained and upgraded without interrupting or recreating it again.'}
      ]
    };
  }


  viewProcess(index) {
    this.activeProcessIndex = index;
  }

}

const
  caseStudies = [
    {
      id: 'momentpin',
      title: 'Momentpin',
      short_description: 'Our expert software development company',
      description: ' successfully designed and developed a Social App to Capture & Share Blissful Moments in Your Life.',
      images : [
        {url : 'momentpin/momentpin-mobile.png' , animated : 'fade-up', duration : 400},
        {url : 'momentpin/momentpin-210.png' , animated : 'fade-left', duration : 300, delay : 500},
        {url : 'momentpin/momentpin-black-label.png' , animated : 'fade-left', duration : 300, delay : 500},
        {url : 'momentpin/momentpin-close.png' , animated : 'fade-left', duration : 300, delay : 500},
      ],
      store: {
        play_store : 'https://play.google.com/store/apps/details?id=com.momentpin',
        app_store : 'https://itunes.apple.com/us/app/momentpin/id1356042470',
      },
      bg : '#27b062',
    },
    {
      id: 'shopping-talks',
      title: 'Shoptalk',
      short_description: 'Not just any online store, Shoptalk delivers',
      description: ' shoppers with a whole new experience of fashion exploration and social networking.',
      images : [{url : 'shopping-talks/iphone-left.png' , animated : 'fade-up', duration : 500 },
        {url : 'shopping-talks/iphone-right.png' , animated : 'fade-left', duration : 500 , delay: 500 }],
      bg : '#066350'
    },
    {
      id: 'event-slack',
      title: 'Event Slack',
      short_description: 'Our custom software development company',
      description: ' Houston has stepped in and renovated the concept of socializing. You will never miss out on an event near you.',
      images : [{url : 'event-slack/event-slack-mac.png' , animated : 'zoom-in', }],
      bg : '#0b4090'
    },
    {
      id: 'shopwell',
      title: 'Shopwell',
      short_description: 'We’ve stepped in to digitalize and renovate',
      description: ' the concept of shopping. Customers can find their favorite clothes and accessories at amazing discounts and deals.',
      images : [{url : 'shopwell/banner-phone.png' , animated : 'fade-up-left', duration : 500 , delay: '300'}],
      bg : '#43ebc1',
    },
    {
      id: '1deal',
      title: '1 deal',
      short_description: 'The combined effort of our expert software',
      description: ' developers has resulted in an amazing eCommerce store that offers premium products at the best deals and discounts.',
      images : [{url : 'one-deal/snapshot-middle.png' , animated : 'fade-up', duration : 500}],
      bg : '#4b3ea8',
    },
    {
      id: 'esupply-system',
      title: 'eSupplySystem',
      short_description: 'A modern profit management system that',
      description: ' can connect customers with budget-friendly and authentic retailers. Meeting the customer’s needs quality standards.',
      images : [{url : 'single-case-study/e-supply.png' , animated : 'zoom-in', duration : 500}],
      bg : '#00baff',
    },
    {
      id: 'altanova',
      title: 'Altanova',
      short_description: 'Auto-manage your company’s work log with an',
      description: ' efficient and modern management solution. We deliver full turn-key test interface solutions and log management.', 
      images : [{url : 'altanova/hr-screen.png' , animated : 'zoom-in', duration : 500}],
      bg : '#16cda1',
    }
  ];



export class CaseStudiesDataService {
  getCaseStudies () {
    return caseStudies;
  }

  homePageCaseStudies() {
    return caseStudies;
  }

  getCaseStudy(id) {
    return [caseStudies.find(caseStudy => id === caseStudy.id)];
  }
}

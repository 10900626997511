import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

// const baseUrl = 'http://localhost:4201/wp-json/wp/v2/';
// const baseUrl = 'https://staging.koderlabs.com/blog/wp-json/wp/v2/';
const baseUrl = 'https://blog.koderlabs.com/wp-json/wp/v2/';


@Injectable({
  providedIn: 'root'
})

export class BlogServices {
  constructor(private http: HttpClient) { }

  public store: any = {};
  public posts = [];

  api(url, params, slug, error) {

    return new Promise<any>((resolve, reject) => {

      if (error) {
        reject(error);
      }

      if (params === 'slug') {
        const post = this.posts.find(p => p.slug === slug);
        if (post) {
          resolve(post);
          return;
        }
      } else if (this.store[url]) {
        resolve(this.store[url]);
        return;
      }

      this.http.get(baseUrl + url)
        .toPromise()
        .then(
          res => {
            let response = res;
            if (params !== 'slug') {
              this.store[url] = response;
            }
            if (params === 'posts') {
              this.posts = this.posts.concat(res);
            } else if (params === 'slug') {
              response = res[0];
              this.posts.push(response);
            }
            resolve(response);
          },
          msg => reject(msg)
        );
    });
  }

  getCategories() {
    return this.api('categories', null, null, null);
  }

  getSearchPosts(query) {
    const url = `posts/?search=${query}`;
    return this.api(url, 'posts', null, null);
  }

  getPosts(page, limit) {
    const url = `posts/?per_page=${limit}&page=${page}`;
    return this.api(url, 'posts', null, null);
  }

  getPost(slug) {
    const url = `posts/?slug=${slug}`;
    return this.api(url, 'slug', slug, null);
  }

  getPostByCategory(categories, page, limit) {
    let getCategory: any =  '';
    const store = this.store;

    if (categories && store.categories && store.categories.length) {
      getCategory  = store.categories.find(cat => cat.name === categories);
    }

    //console.log('getCategory', getCategory);

    const error = getCategory ? '' : 'Not match category in this criteria',
      url = getCategory ?  `posts/?categories=${getCategory.id}&per_page=${limit}&page=${page}` : '';
    return this.api(url, 'cat-post', null, error);
  }
}


import { CalculateComponent } from "./../../pages/calculate/calculate.component";
import { Component, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TopViewComponent } from "../../components/top-view/top-view.component";
import { PageDownMouseBtnComponent } from "../../components/page-down-mouse-btn/page-down-mouse-btn.component";
import { RouterModule } from "@angular/router";
import { CtaFormComponent } from "../../components/cta-form/cta-form.component";
import { CtaFormEcommerceComponent } from "../../components/cta-form-ecommerce/cta-form-ecommerce.component";
import { CtaFormPopupformComponent } from "../../components/cta-form-popupform/cta-form-popupform.component";
import { CasestudiesComponent } from "../../components/casestudies/casestudies.component";
import { FeaturesComponent } from "../../components/features/features.component";
import { TechnologiesComponent } from "../../components/technologies/technologies.component";
import { PaltformsComponent } from "../../components/paltforms/paltforms.component";
import { PlaneCardsComponent } from "../../components/plane-cards/plane-cards.component";
import { BannerTextComponent } from "../../components/banner-text/banner-text.component";
import { ModalComponent } from "../../components/modal/modal.component";
import { Ng2TelInputModule } from "ng2-tel-input";
import { CareerCardsComponent } from "src/app/components/career-cards/career-cards.component";

@NgModule({
  declarations: [
    TopViewComponent,
    PageDownMouseBtnComponent,
    CasestudiesComponent,
    CtaFormComponent,
    CtaFormEcommerceComponent,
    CtaFormPopupformComponent,
    FeaturesComponent,
    TechnologiesComponent,
    PaltformsComponent,
    PlaneCardsComponent,
    CareerCardsComponent,
    BannerTextComponent,
    ModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2TelInputModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TopViewComponent,
    PageDownMouseBtnComponent,
    CasestudiesComponent,
    CtaFormComponent,
    CtaFormEcommerceComponent,
    CtaFormPopupformComponent,
    FeaturesComponent,
    TechnologiesComponent,
    PaltformsComponent,
    PlaneCardsComponent,
    CareerCardsComponent,
    BannerTextComponent,
    ModalComponent,
    Ng2TelInputModule,
  ],
})
export class SharedModule {}

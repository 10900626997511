import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  //@Input() routeUrl: any;

  @Input() set routeUrl(value: string) {

    if(typeof value !== 'undefined' ){
      console.log(value,"VALUE")
      this.defaultMenu = !(value.includes('/magento') ||  value.includes('/shopify') || value.includes('/privacy_policy') ||  value.includes('/woocommerce') ||  value.includes('/thankyou')  ||  value.includes('/webdesign') ||  value.includes('/ecommerce'));
    }
 };


  public defaultMenu : boolean = true;
  constructor(){

  }

  ngOnInit(){
    //this.defaultMenu = !(this.routeUrl.includes('/magento') ||  this.routeUrl.includes('/shopify') ||  this.routeUrl.includes('/woocommerce') ||  this.routeUrl.includes('/ecommerce'));
  }

  currentYearLong(): number {
    return new Date().getFullYear();
    }

}

import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';

import {EventsService} from '../../services/events.service';
import {HelperService} from '../../services/helper.service';

declare var jQuery: any;


@Component({
  selector: 'app-casestudies',
  templateUrl: './casestudies.component.html',
  styleUrls: ['./casestudies.component.scss']
})
export class CasestudiesComponent implements OnInit, OnDestroy {
  @Input() caseStudies: any;
  @Input() page: any;
  @ViewChild('caseStudiesViews') caseStudiesViews: ElementRef;

  public subscription: Subscription;
  public bg: any = {};
  // public isCollapsed: boolean  = false;

  constructor(
    private eventsService: EventsService,
    private helperService: HelperService
  ) {}


  ngOnInit() {
    this.changeBg(0);
    if (this.page !== 'case-study') {
      this.subscription = this.eventsService.scrollEmitter.subscribe((window) => {
        this.caseStudyViewPort(window);
      });
    }
  }

  ngOnDestroy() {
    if (this.page !== 'case-study') {
      this.subscription.unsubscribe();
    }
  }

  caseStudyViewPort(win) {
    const self = this;
    jQuery(this.caseStudiesViews.nativeElement).find('.single-case-study').each( function() {
      const eleIsViewPort = self.helperService.elementInView(this, win, 2, 0);
      if (eleIsViewPort) {
        const id = jQuery(this).attr('id');
        self.changeBg(id);
      }
    });
  }


  changeBg(id) {
    let bg = '#066350';
    if (id) {
      const active = this.caseStudies.find(caseStudy => id === caseStudy.id);
      bg = active.bg;
    } else {
      bg = this.caseStudies[0].bg;
    }
    this.bg = {
      'background-color': bg
    };
  }

  // toggleCollapse() {
  //   this.isCollapsed = !this.isCollapsed;
  // }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-plane-cards',
  templateUrl: './plane-cards.component.html',
  styleUrls: ['./plane-cards.component.scss']
})
export class PlaneCardsComponent implements OnInit {

  constructor() { }


@Input() data: any;
  ngOnInit() {
  }

}

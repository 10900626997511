import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-career-page",
  templateUrl: "./career-page.component.html",
  styleUrls: ["./career-page.component.scss"],
})
export class CareerPageComponent implements OnInit {
  public career: any;

  ngOnInit() {
    this.career = {
      title: `Careers`,
      items: [
        {
          name: "Software Architect",
          description: `<ul><li>KoderLabs, LLC seeks Software Architect.</li> <li>This person works from 9:00 AM - 5:00 PM (Central Time Zone). Because it works closely with teams in Karachi, Pakistan</li> <li>This role must also be available from 11:00 PM until 12:30 AM (Central Time Zone).</li> <li>Primary work location is Grapevine, Texas but telecommuting from the Dallas - Forth Worth Metro area is allowed on a 30% basis.</li> <li>Send resume to: 2451 West Grapevine Mills Circle, #116, Grapevine, Texas 76051.</li> <li>No phone calls.</li></ul>`,
        },
      ],
    };
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';

const request = {
    submit : false,
    success : false,
    error : false,
  },
  error = {
    submit : true,
    success : false,
    error : true,
  },
  success = {
    submit : true,
    success : true,
    error : false,
  };

@Component({
  selector: 'app-cta-form-popupform',
  templateUrl: './cta-form-popupform.component.html',
  styleUrls: ['./cta-form-popupform.component.scss']
})
export class CtaFormPopupformComponent implements OnInit {

  public talkToUsForm: FormGroup;
  public formClass: any = '';
  public submitted: any = false;
  public loading: any = false;
  public request: any = request;
  route: string;
  public href = '';
  url = 'asdf';
  dialCode: any = '1';

  constructor(private http: HttpClient, private router: Router) {
  }

  @Input() name: any;

  ngOnInit() {

    this.href = this.router.url;
    // console.log("testing url" + this.router.url);

    this.formClass = this.name || '';
    this.talkToUsForm = new FormGroup({
      name : new FormControl(''),
      phone : new FormControl('', Validators.required),
      email : new FormControl('', Validators.required),
      company : new FormControl(''),
      message : new FormControl(''),
      budget : new FormControl(''),
      pageRoute : new FormControl(''),
      dialCode : new FormControl(''),
      // services: new FormGroup({
      //   woocommerce : new FormControl(false),
      //   magento : new FormControl(false),
      //   shopify : new FormControl(false),
      // })
    });
  }
  clickMe() {
    console.log('CLICK ME');
  }

  getNumber(obj) {
    // console.log(obj);
  }
  hasError(obj) {
    console.log(obj);
  }
  telInputObject(obj) {
    // console.log(obj);
  }

  onCountryChange(obj) {
    console.log(obj.dialCode);
    this.dialCode = obj.dialCode;
  }

  onSubmit() {

    this.submitted = true;

    if (this.talkToUsForm.invalid) {
      return null;
    }

    this.loading = true;
    this.href = this.router.url;
    const pageUrl = this.href;
    this.talkToUsForm.value.pageRoute = pageUrl;
    this.talkToUsForm.value.dialCode = this.dialCode;
    console.log( this.talkToUsForm.value);

    const initreferrer = document.referrer;

    if (localStorage.getItem('mysite_referrer') === null) {
          localStorage.setItem('mysite_referrer', initreferrer);
    }

    if (localStorage.getItem('mysite_referrer')) {
      this.talkToUsForm.value.refUrl = localStorage.getItem('mysite_referrer');
      console.log('now defined');
    } else {
      console.log('undefined');
    }

    this.http.post<any>(`https://koderlabs.com:3000/mail/magento`, this.talkToUsForm.value)
      .subscribe(
        resp => {
          this.request = (resp && resp.length && resp[1] && resp[1].status) ? success : error;
          document.getElementById('clickHere').click();
          this.loading = false;
        },
        err => {
          this.request = error;
          this.loading = false;
        }
      );
  }

  tryAgain() {
    this.request = request;
  }


}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paltforms',
  templateUrl: './paltforms.component.html',
  styleUrls: ['./paltforms.component.scss']
})
export class PaltformsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { ContactComponent } from "./pages/contact/contact.component";
import { CalculateComponent } from "./pages/calculate/calculate.component";
import { TechnologiesPageComponent } from "./pages/technologies-page/technologies-page.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { TermsServiceComponent } from "./pages/terms-service/terms-service.component";
import { CareerPageComponent } from "./pages/career-page/career-page.component";

const routes: Routes = [
  { path: "", loadChildren: "./modules/home/home.module#HomeModule" },
  {
    path: "case_studies",
    loadChildren: "./modules/casestudies/casestudies.module#CaseStudiesModule",
  },

  { path: "about", loadChildren: "./modules/about/about.module#AboutModule" },
  {
    path: "services",
    loadChildren: "./modules/services/services.module#ServicesModule",
  },
  { path: "calculator", component: CalculateComponent },
  { path: "contact", component: ContactComponent },
  { path: "technologies", component: TechnologiesPageComponent },
  { path: "careers", component: CareerPageComponent },
  { path: "privacy_policy", component: PrivacyPolicyComponent },
  { path: "terms_service", component: TermsServiceComponent },
  {
    path: "magento",
    loadChildren: "./modules/magento/magento.module#MagentoModule",
  },
  {
    path: "magento-migration",
    loadChildren:
      "./modules/magento-migration/magento-migration.module#MagentoMigrationModule",
  },
  {
    path: "magento-support",
    loadChildren:
      "./modules/magento-support/magento-support.module#MagentoSupportModule",
  },
  {
    path: "shopify",
    loadChildren: "./modules/shopify/shopify.module#ShopifyModule",
  },
  {
    path: "woocommerce",
    loadChildren: "./modules/woocommerce/woocommerce.module#WoocommerceModule",
  },
  {
    path: "webdesign",
    loadChildren: "./modules/webdesign/webdesign.module#WebdesignModule",
  },
  { path: "ai", loadChildren: "./modules/ai/ai.module#AiModule" },
  {
    path: "ecommerce",
    loadChildren: "./modules/ecommerce/ecommerce.module#EcommerceModule",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-talk-to-us',
  templateUrl: './talk-to-us.component.html',
  styleUrls: ['./talk-to-us.component.scss']
})

export class TalkToUsComponent implements OnInit {
  @Input() set routeUrlTalk(value: string) {

    if (typeof value !== 'undefined' ) {
      // tslint:disable-next-line: max-line-length

      setTimeout(()=>{
        this.defaultMenu = !(value.includes('/magento') || value.includes('/shopify') || value.includes('/woocommerce') || value.includes('/webdesign') || value.includes('/thankyou') || value.includes('/ecommerce') || value.includes('/magento-support') || value.includes('/magento-migration'));
      },3000)

     }
 }

 public defaultMenu = true;

 ngOnInit() {
   // tslint:disable-next-line: max-line-length
   // this.defaultMenu = !(this.routeUrl.includes('/magento') ||  this.routeUrl.includes('/shopify') ||  this.routeUrl.includes('/woocommerce') ||  this.routeUrl.includes('/ecommerce'));
 }

}

import { Injectable} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import seo from '../constants/seo';

const defaultTag = {
  title: 'Custom Software Development | Software Development Company in Houston,Texas',
  keywords: '',
  description: '',
  author: 'koderlabs',
  robots: 'index, follow'
};


@Injectable()

export class SeoService {

  constructor(private meta: Meta,  private title: Title) {
  }

  add(obj) {

    const tag = this.findTag(obj);
    this.title.setTitle(tag.title || defaultTag.title);
    this.meta.addTag({name: 'keywords', content: tag.keywords || defaultTag.keywords});
    this.meta.addTag({name: 'description', content: tag.description || defaultTag.description});
    this.meta.addTag({name: 'author', content:  tag.author || defaultTag.author});
    this.meta.addTag({name: 'robots', content: tag.robots || defaultTag.robots});
  }

  findTag(obj) {
    if (!obj && !obj.url) {
      return defaultTag;
    }

    const urlSplit = obj.url.split('/');

    if ( !urlSplit.length ) {
      return defaultTag;
    }

      const   url = urlSplit[urlSplit.length - 1],
      getTag = seo.seo.find((tag) => tag.url === url);
      return getTag || defaultTag;
  }

}

import { Component, OnInit, HostListener, Input } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { EventsService } from "./services/events.service";
import { SeoService } from "./services/seo.service";

import * as AOS from "aos";

declare var jQuery: any;
declare var drift: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public activeRoute: any = false;
  public talkToUsHide: any = false;
  public style: any = {};
  public parallaxFooter: any;
  public appClass: any = "app-root";
  public routeUrl: any;
  public routeUrlHeader: any;
  public routeUrlTalk: any;
  public loaded = false;

  constructor(
    private router: Router,
    public eventsService: EventsService,
    private seoService: SeoService
  ) {}

  // Event listener win on scroll
  @HostListener("window:scroll", ["$event"]) onScrollEvent($event) {
    const win = $event.path[1];
    this.footerStyle(win);
    this.eventsService.updateOnScroll(win);
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    console.log(this.eventsService.pageLoaded, " APP Component");
    this.loaded = true;
  }

  ngOnInit() {
    // console.log(this.router.url);
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.activeRoute = true;
        return;
      }
      if (!(evt instanceof NavigationEnd)) {
        this.stateChange();
        return;
      }

      this.seoService.add(evt);

      this.style = {};

      if (evt.url.includes("#")) {
        this.scrollToId(evt.url);
      }
      // tslint:disable-next-line: max-line-length
      if (
        evt.url.includes("case_studies") ||
        evt.url.includes("careers") ||
        evt.url.includes("blogs") ||
        evt.url.includes("magento") ||
        evt.url.includes("shopify") ||
        evt.url.includes("woocommerce") ||
        evt.url.includes("thankyou") ||
        evt.url.includes("webdesign") ||
        evt.url.includes("ai") ||
        evt.url.includes("ecommerce")
      ) {
        this.style = { "padding-top": 0 };
      }
      // tslint:disable-next-line: max-line-length
      if (
        evt.url.includes("magento") ||
        evt.url.includes("shopify") ||
        evt.url.includes("woocommerce") ||
        evt.url.includes("thankyou") ||
        evt.url.includes("webdesign") ||
        evt.url.includes("ai") ||
        evt.url.includes("ecommerce")
      ) {
        $("#remove").remove();
      }
      this.routeUrl = evt.url;
      // console.log("App : ",this.routeUrl);
      this.routeUrlHeader = evt.url;
      // console.log("App : ",this.routeUrlHeader);
      this.routeUrlTalk = evt.url;
      // console.log("App : ",this.routeUrlTalk);
      this.talkToUsHide =
        evt.url === "/contact" ||
        evt.url === "/privacy_policy" ||
        evt.url === "/terms_service" ||
        evt.url === "/careers";
      // tslint:disable-next-line: max-line-length
      this.appClass =
        evt.url === "/contact" ||
        evt.url === "/careers" ||
        evt.url === "/privacy_policy" ||
        evt.url === "/terms_service" ||
        evt.url === "/shopify" ||
        evt.url === "/magento" ||
        evt.url === "/magento-migration" ||
        evt.url === "/magento-support" ||
        evt.url === "/woocommerce1" ||
        evt.url === "/thankyou" ||
        evt.url === "/webdesign" ||
        evt.url === "/ai" ||
        evt.url === "/ecommerce"
          ? "contact-page"
          : "app-root";
      // window.scrollTo(0, 0);
      setTimeout(() => {
        AOS.refreshHard(); /* refresh aos animation */
      }, 0);

      // tslint:disable-next-line: max-line-length
      if (
        evt.url.includes("magento") ||
        evt.url.includes("shopify") ||
        evt.url.includes("woocommerce") ||
        evt.url.includes("thankyou") ||
        evt.url.includes("webdesign") ||
        evt.url.includes("ai") ||
        evt.url.includes("ecommerce")
      ) {
      }

      // tslint:disable-next-line: deprecation
      $(document).ready(function () {
        $("#remove").remove();
      });
    });

    AOS.init({
      delay: 300,
      duration: 400,
      easing: "ease-in",
      once: true,
      anchorPlacement: "bottom-center",
    });
  }

  stateChange() {
    setTimeout(() => {
      this.activeRoute = false;
    }, 1000);
  }

  footerStyle(win) {
    if (this.loaded === false) {
      this.parallaxFooter = {
        "z-index": 0,
      };
      return;
    }

    const fourthHeightOfWindow = win.innerHeight / 4,
      currentPosition = win.scrollY;
    this.parallaxFooter = {
      "z-index": win.scrollY > win.innerHeight + 200 ? 1 : 0,
    };
  }

  // casestudies details page
  scrollToId(url) {
    const id = url.split("#")[1];

    // jQuery('html, body').animate({
    //   scrollTop: jQuery(`#${id}`).offset().top
    // });
    window.scrollTo({
      top: document.getElementById(id).offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }
}

// declare var jQuery: any;


export class HelperService {

  // Check element is visible in dom or not
  // elementInViewPort(element, window, ratio) {
  //   const
  //     isRatio = ratio || 2,
  //     $ele = jQuery(element),
  //     elementTop = $ele.offset().top,
  //     elementBottom = elementTop + $ele.outerHeight(),
  //     viewportTop = window.scrollY,
  //     viewportBottom = viewportTop + (window.innerHeight  / isRatio ) ;
  //   return elementBottom > viewportTop && elementTop < viewportBottom;
  // }

  elementInView(element, window, ratio, offset) {
    const rect = element.getBoundingClientRect(),
      isRatio = ratio || 2,
      heightOffset = offset || 0;
    return (
      // The top is in view: the top is more than 0 and less than the window height (the top of the element is in view)
      ((rect.top + heightOffset) >= 0 && (rect.top + heightOffset) <= window.innerHeight  / isRatio ) ||
      // The bottom is in view: bottom position is greater than 0 and greater than the window height
      ((rect.bottom + heightOffset) >= 0 && (rect.bottom + heightOffset) <= window.innerHeight  / isRatio ) ||
      // The top is above the viewport and the bottom is below the viewport
      ((rect.top + heightOffset) < 0 && (rect.bottom + heightOffset) > window.innerHeight / isRatio )
    );
  }




  scroll(scrollPosition) {
    // const position = scrollPosition || jQuery(window).innerHeight();
    //   animation = speed || 1000;
    // // jQuery('html, body')
    // //   .animate({scrollTop: position}, animation);
    const position = scrollPosition || window.innerHeight;
      window.scrollTo({
        top: position,
        left: 0,
        behavior: 'smooth'
      });
  }

}

import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { CalculateComponent } from "./pages/calculate/calculate.component";
import { TechnologiesPageComponent } from "./pages/technologies-page/technologies-page.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { TermsServiceComponent } from "./pages/terms-service/terms-service.component";
import { AnalysisSvgComponent } from "./components/svg-components/analysis-svg/analysis-svg.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Ng2TelInputModule } from "ng2-tel-input";

import { CoreModule } from "./modules/core/core.module";
import { SharedModule } from "./modules/shared/shared.module";
import { SeoService } from "./services/seo.service";
import { CareerPageComponent } from "./pages/career-page/career-page.component";

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    CalculateComponent,
    TechnologiesPageComponent,
    CareerPageComponent,
    PrivacyPolicyComponent,
    TermsServiceComponent,
    AnalysisSvgComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    HttpClientModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    Ng2TelInputModule,
  ],
  providers: [SeoService],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { NgModule } from '@angular/core';

import {HeaderComponent} from '../../components/header/header.component';
import {FooterComponent} from '../../components/footer/footer.component';
import {TalkToUsComponent} from '../../components/talk-to-us/talk-to-us.component';
import {AppRoutingModule} from '../../app-routing.module';
import {EventsService} from '../../services/events.service';
import {HelperService} from '../../services/helper.service';
import {SideNavComponent} from '../../components/side-nav/side-nav.component';
import {SharedModule} from '../shared/shared.module';
import {HomeModule} from '../home/home.module';
import {CaseStudiesDataService} from '../../services/case-studies-data.service';
import {BlogServices} from '../../services/blog.services';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    TalkToUsComponent,
    SideNavComponent,
  ],
  imports: [
    SharedModule,
    AppRoutingModule
  ],
  exports: [
    AppRoutingModule,
    HeaderComponent,
    FooterComponent,
    TalkToUsComponent,
    SideNavComponent,
    // HomeModule,
  ],
  providers: [
    CaseStudiesDataService,
    EventsService,
    HelperService,
    BlogServices
  ]
})
export class CoreModule {}

import {Component, OnInit, Input, AfterViewInit} from '@angular/core';

import Typed from 'typed.js';

@Component({
  selector: 'app-top-view',
  templateUrl: './top-view.component.html',
  styleUrls: ['./top-view.component.scss']
})
export class TopViewComponent implements OnInit, AfterViewInit {

  @Input() data: any;
  @Input() margin: any;
  @Input() typed: any = 0;
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.typed && window.innerHeight) {
      const typed = new Typed('.code-typing', this.typed);
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

const request = {
    submit : false,
    success : false,
    error : false,
  },
  error = {
    submit : true,
    success : false,
    error : true,
  },
  success = {
    submit : true,
    success : true,
    error : false,
  };

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {


  public contactForm: FormGroup;
  public submitted: any = false;
  public loading: any = false;
  public request: any = request;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.contactForm = new FormGroup({
      name : new FormControl('', Validators.required),
      phone : new FormControl('', Validators.required),
      email : new FormControl('', Validators.required),
      company : new FormControl(''),
      budget : new FormControl(''),
      absolutely : new FormControl(''),
      services: new FormGroup({
        design : new FormControl(false),
        websites_development : new FormControl(false),
        apps_development : new FormControl(false),
        branding : new FormControl(false),
        marketing : new FormControl(false),
        other : new FormControl(false),
      })
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return null;
    }
    this.loading = true;
    this.http.post<any>(`https://koderlabs.com:3000/mail`, this.contactForm.value)
      .subscribe(
        resp => {
          this.request = (resp && resp.length && resp[1] && resp[1].status) ? success : error;
          this.loading = false;
        },
        err => {
          this.request = error;
          this.loading = false;
        }
      );
  }

  tryAgain() {
    this.request = request;
  }

}

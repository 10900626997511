import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-career-cards",
  templateUrl: "./career-cards.component.html",
  styleUrls: ["./career-cards.component.scss"],
})
export class CareerCardsComponent implements OnInit {
  constructor() {}

  @Input() data: any;
  ngOnInit() {}
}

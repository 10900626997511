export default {
  seo : [
    {
      url : '',
      title : 'Custom Software Development | Software Development Company in Houston,Texas',
      keywords : '',
      description : 'Top-notch custom software development company Houston TX. Delivering quality UI/UX designs, mobile application development and custom software solutions',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'about',
      title : 'KoderLabs LLC – About Us',
      keywords : '',
      description : '',
      author : 'koderlabs',
      robots : 'index, follow',
    },
   
    {
      url : 'about',
      title : 'KoderLabs LLC – About Us',
      keywords : '',
      description : '',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'case_studies',
      title : 'Case Studies | TX',
      keywords : '',
      description : 'KoderLabs case studies showcasing robust software solutions developed by some of the finest minds dedicated to their craft of development, design...',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'momentpin',
      title : 'Momentpin - Social App Development',
      keywords : '',
      description : 'Momentpin, a social networking app that allows you to share some of your best moments with friends and family. Start pinning your moments...',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'shopping-talks',
      title : 'Shopping Talks - Ecommerce App Development',
      keywords : '',
      description : 'Shoptalk is a mobile app providing you with a seamless online shopping experience with its modern sleek design, easy navigation, and much more...',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'event-slack',
      title : 'Case study - event slack',
      keywords : '',
      description : 'KoderLabs with its team of experts has created "Event Slack", a social media application allowing you to keep track of events near you....',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'shopwell',
      title : 'Case study - shop well',
      keywords : '',
      description : 'Shopwell is a mobile app, creation of KoderLabs, providing you with the best deals on your favorite clothing items, revolutionizing online shopping...',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : '1deal',
      title : 'Case study - 1deal',
      keywords : '',
      description : '1deal is an eCommerce store that offers premium products at best deals and discounts. Developed by KoderLabs, you receive high-quality and...',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'esupply-system',
      title : 'Case Study - e Supply System',
      keywords : '',
      description : 'eSupply System is KoderLabs creation, a modern profit management system that connects customers with budget-friendly and authentic retailers....',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'altanova',
      title : 'Case study - altanova',
      keywords : '',
      description : 'A modern work management solution, developed by KoderLabs, that allows you to auto-manage your companys work log. Altanova is your best work solution....',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'ecommerce',
      title : 'eCommerce website design & development company',
      keywords : '',
      description : 'Considering for world-class eCommerce development company? Get professional eCommerce development services and strong website designing solutions at economical price.',
      author : 'koderlabs',
      robots : 'noindex',
    },

    {
      url : 'magento',
      title : 'Magento Development Company | Magento Web Development Services',
      keywords : '',
      description : 'Koderlabs is a leading magento web development company in Houston, offering top superiority magento web development services to business around the world',
      author : 'koderlabs',
      robots : 'noindex',
    },

    {
      url : 'magento-migration',
      title : 'Magento Migration | Magento Web Development Services',
      keywords : '',
      description : 'Koderlabs is a leading magento web development company in Houston, offering top superiority magento web development services to business around the world',
      author : 'koderlabs',
      robots : 'noindex',
    },

    {
      url : 'magento-support',
      title : 'Magento Support | Magento Web Development Services',
      keywords : '',
      description : 'Koderlabs is a leading magento web development company in Houston, offering top superiority magento web development services to business around the world',
      author : 'koderlabs',
      robots : 'noindex',
    },

    {
      url : 'shopify',
      title : 'Shopify Development Services | Shopify Development Company Houston',
      keywords : '',
      description : 'We are best shopify development company in Houston. We offering custom shopify theme development, API integration and maintenance services.',
      author : 'koderlabs',
      robots : 'noindex',
    },

    {
      url : 'woocommerce',
      title : 'WooCommerce Development Services Company | Koderlabs',
      keywords : '',
      description : 'Koderlabs is a leading woocommerce development company in Houston. Having certified developers dedicated to structure successful ecommerce stores for their clients',
      author : 'koderlabs',
      robots : 'noindex',
    },

    {
      url : 'thankyou',
      title : 'Thank You | Koderlabs',
      keywords : '',
      description : 'Koderlabs is a leading woocommerce development company in Houston. Having certified developers dedicated to structure successful ecommerce stores for their clients',
      author : 'koderlabs',
      robots : 'noindex',
    },

    {
      url : 'webdesign',
      title : 'Custom Website Design & Development | Koderlabs',
      keywords : '',
      description : 'Koderlabs is a leading Web Design development company in Houston. Having certified developers dedicated to structure successful ecommerce stores for their clients',
      author : 'koderlabs',
      robots : 'noindex',
    },

    {
      url : 'ui-ux-design',
      title : 'KoderLabs LLC – Exclusive UI / UX Design',
      keywords : '',
      description : 'Exclusive UI/UX designs that deliver incredible experience in a glance. Our UI and UX designers work closely to outline your product with premium layout.',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'website_development',
      title : 'KoderLabs LLC – Distinctive Website Development',
      keywords : '',
      description : 'Premium quality Website development and Web application services that are design to boost business and generate leads instantly',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'android_app_development',
      title : 'KoderLabs LLC –  Android App Development',
      keywords : '',
      description : 'On-Demand high quality Android App Development services by expert android developers team | iOS &amp; Android Apps | Apps &amp; Prototypes',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'ios_app_development',
      title : 'KoderLabs LLC – iOS App Development',
      keywords : '',
      description : 'Koderlabs is a leading USA-based iOS app development company. Our knowledgeable iOS app developers specialize in custom iOS app development',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'technologies',
      title : 'KoderLabs LLC – Technologies',
      keywords : '',
      description : 'KoderLabs bring your ideas to realities with powerful technologies that are latest, updated, and current. Our tech stack enables us to create...',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'contact',
      title : 'KoderLabs LLC – Contact',
      keywords : '',
      description : 'We would love to collaborate and take on your idea and turn it into a functional and winning software solution. So contact KoderLabs and lets get started',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'blogs',
      title : 'KoderLabs LLC –  Blogs',
      keywords : '',
      description : 'Looking for IT Services? Web App or Mobile application, all build here with premium quality. Contact the leading software developer company in Dallas, TX',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'software-development-trends-of-2020',
      title : 'Software Development Trends That Are Here To Stay In 2020',
      keywords : '',
      description : 'Software development trends have to accommodate the rapidly changing technologies. Year 2020 is no different with newer software development trends that will stick around for a longer time.',
      author : 'koderlabs',
      robots : 'index, follow',
    },
    
    {
      url : '5-top-open-source-api-testing-tools',
      title : 'Open-Source API Testing Tools: Here Are Our Top 5 Picks',
      keywords : '',
      description : 'API testing has become a crucial part as the microservices approach to software dev increases. Here are top 5 open-source API testing tools easily available.',
      author : 'koderlabs',
      robots : 'index, follow',
    },
    
    {
      url : 'upcoming-smartphones-of-2020',
      title : '5 Upcoming Smartphones To Look Out For In 2020',
      keywords : '',
      description : 'There is a long line of smartphones expected to launch in 2020, with better performance.',
      author : 'koderlabs',
      robots : 'index, follow',
    },
    
    {
      url : 'benefits-of-outsourcing-your-website',
      title : '7 Benefits Of Outsourcing Your Website Requirements',
      keywords : '',
      description : 'Outsourcing your IT requirements, such as developing and maintaining your business\'s website, to gain competitive edge in the market is beneficial for you.',
      author : 'koderlabs',
      robots : 'index, follow',
    },
    
    {
      url : 'disney-plus-collects-100-million-revenue',
      title : 'Disney Plus Dominates The Streaming Arena With Impressive New Figures',
      keywords : '',
      description : 'Disney Plus is making headlines again as it collects nearly $100m in revenue within just two months of its launch',
      author : 'koderlabs',
      robots : 'index, follow',
    },
    
    {
      url : '5-impacts-of-great-ux-design-on-business',
      title : '5 Positive Impacts Of A Great UI/UX Design On Your Business',
      keywords : '',
      description : 'In a time where aesthetics have become a trend, it is imperative to bank on a great UI/UX design that provides an excellent user experience, ultimately ensuring the business\'s success.',
      author : 'koderlabs',
      robots : 'index, follow',
    },
     
    {
      url : 'make-money-from-your-free-mobile-app',
      title : 'Free Mobile Apps: How Can You Gain Profit From Them?',
      keywords : '',
      description : 'Generating revenue from your mobile app that is available on app market for free, is do-able with the right monetization methods.',
      author : 'koderlabs',
      robots : 'index, follow',
    },
    
    {
      url : 'koderlabss-momentpin-ready-for-an-exclusive-feature-at-the-39th-gitex-technology-week-2019',
      title : 'KoderLabs’s Exclusive Feature at The 39th GITEX Technology Week 2019',
      keywords : '',
      description : 'GITEX is an innovation situated display in the MENA locale where mammoths like Amazon Web Services to China Mobile gladly present their extraordinary offers.',
      author : 'koderlabs',
      robots : 'index, follow',
    },
    
    {
      url : 'all-you-need-to-know-about-managed-extensible-framework',
      title : 'Need to Know About ‘Managed Extensible Framework',
      keywords : '',
      description : 'Managed Extensible Framework is a structure from Microsoft Corporation to manufacture Extensible applications.',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'unleash-the-potential-of-digital-marketing-through-augmented-reality-2019',
      title : 'Potential of Digital Marketing through Augmented Reality 2019',
      keywords : '',
      description : 'Today media and promoting offices are taking the substance and notices to the following level that has expanded the income obviously.',
      author : 'koderlabs',
      robots : 'index, follow',
    },
    
    {
      url : 'basic-guide-agile-approach-for-successful-software-development',
      title : 'Agile Approach for Successful Software Development',
      keywords : '',
      description : 'For example, there is a differing assortment of programming that takes a specific degree of remaining task at hand, assets and obviously spending plan.',
      author : 'koderlabs',
      robots : 'index, follow',
    },
    
    {
      url : 'why-work-with-koderlabs-software-development-company-dallas',
      title : 'Work With KoderLabs Software Development Company Dallas?',
      keywords : '',
      description : 'What is the primary thing that comes in your mind when you think about a lab? Some geeky researcher mindfully testing, peaceful and occupied rooms with specialists inquiring about on their hypotheses, isn\'t that so?',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    {
      url : 'privacy_policy',
      title : 'Privacy Policy | KoderLabs',
      keywords : '',
      description : 'KoderLabs values transparency and building trust with our clients on the right foundations. Our PRIVACY POLICY translates everything you need to know...',
      author : 'koderlabs',
      robots : 'index, follow',
    },

    
    {
      url : 'services',
      title : 'Services | KoderLabs',
      keywords : '',
      description : '',
      author : 'koderlabs',
      robots : 'index, follow',
    },
  ]
};
import {Component, Input, OnInit} from '@angular/core';
import {EventsService} from '../../services/events.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  constructor(private eventsService: EventsService, public router: Router) { }

  @Input() opened: any;
  public transparent: any;
  public openServices: any = false;
  public openServicesEcom: any = false;
  public defaultMenu : boolean = true;

  @Input() set sideNavUrl(value: string) {

    // this._categoryId = value;
    // this.doSomething(this._categoryId);
    if(typeof value !== 'undefined' ){
      //console.log(value,"VALUE SIDENAV")
      this.defaultMenu = !(value.includes('/magento') ||  value.includes('/shopify') ||  value.includes('/woocommerce') ||  value.includes('/webdesign') ||  value.includes('/thankyou') ||  value.includes('/ecommerce'));
    }
 };

  ngOnInit() {
    
    this.eventsService.menuButtonEmitter.subscribe((bol) => {
      this.opened = bol;
      this.transparent = bol;
    });
    
    //console.log("SIDENAV : ", this.defaultMenu);
  }

  toggleServices() {
    this.openServices = !this.openServices;
  }
  toggleServicesEcom() {
    this.openServicesEcom = !this.openServicesEcom;
  }


  close() {
    this.opened = !this.opened;
    setTimeout(() => {
      this.eventsService.toggleMenu(this.opened);
      this.transparent  = this.opened;
    }, 400);

  }

}

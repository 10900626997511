import {AfterViewInit, Component, Input} from '@angular/core';
import {HelperService} from '../../services/helper.service';
// declare var jQuery: any;

@Component({
  selector: 'app-page-down-mouse-btn',
  templateUrl: './page-down-mouse-btn.component.html',
  styleUrls: ['./page-down-mouse-btn.component.scss']
})
export class PageDownMouseBtnComponent implements AfterViewInit {

  private scrollTop: any;
  constructor(private helperService: HelperService) { }
  @Input() placement: any;
  @Input() margin: any;


  ngAfterViewInit() {
    // const windowHeight = jQuery(window).innerHeight();
    const windowHeight = window.innerHeight;
    this.scrollTop = this.placement ? 2 * windowHeight : windowHeight;
    if (this.margin) {
      this.scrollTop += this.margin;
    }
  }

  pageDown() {
    this.helperService.scroll(this.scrollTop);
  }

}

import {EventEmitter} from '@angular/core';
import {Subject} from 'rxjs';

export class EventsService {
  pageLoaded = false;
  menuButtonEmitter = new Subject();
  scrollEmitter = new Subject();

  toggleMenu(bol) {
    this.menuButtonEmitter.next(bol);
  }

  updateOnScroll(window) {
    this.scrollEmitter.next(window);
  }
}

import {Component, OnInit, Input} from '@angular/core';


@Component({
  selector: 'app-banner-text',
  templateUrl: './banner-text.component.html',
  styleUrls: ['./banner-text.component.scss']
})

export class BannerTextComponent implements OnInit {

  @Input() data: any;
 
  constructor() {}

  ngOnInit() {
      //console.log('this.data' , this.data);
  }


}
